<template>
  <div>
    <PageLoader v-bind:storage="appLoading" />
    <ServerError v-if="ServerError" />
    <!-- <v-app id="inspire"> -->
    <v-layout wrap justify-center>
      <v-flex xs12 sm6 md4 lg4 px-2 pt-16 align-self-center>
        <v-layout wrap justify-center>
          <v-flex xs112 xl10>
            <SnackBar
              v-bind:msg="msg"
              v-bind:showSnackBar="showSnackBar"
              @stepper="showSnackBar = false"
            />
            <v-card tile class="rounded-xl">
              <v-layout wrap justify-center>
                <v-flex xs12 px-8 pt-12>
                  <v-layout wrap justify-center>
                    <!-- <v-flex xs12 py-4>
                        <v-img
                          src="./../../assets/Images/agogo.png"
                          height="70px"
                          contain
                        ></v-img>
                      </v-flex> -->
                    <v-flex xs12 pt-4>
                      <span
                        style="
                          font-family: poppinsmedium;
                          font-size: 18px;
                          color: #090909;
                        "
                        >OTP Verification</span
                      >
                    </v-flex>
                    <v-flex xs12>
                      <span
                        style="
                          font-family: sofialight;
                          font-size: 12px;
                          color: #7b7b7b;
                        "
                        >Please enter OTP sent to your Mobile
                      </span>
                    </v-flex>
                    <v-flex xs11 sm11 lg8 xl5 pt-8 pl-4 pl-lg-2 text-center>
                      <vie-otp-input
                        inputClasses="otp-input"
                        :numInputs="4"
                        separator
                        :shouldAutoFocus="true"
                        @on-complete="validateOTP"
                      />
                    </v-flex>
                    <v-flex xs12 pt-9>
                      <v-btn
                        block
                        :color="appColor"
                        dark
                        :ripple="false"
                        depressed
                        @click="validateInput"
                      >
                        <span
                          style="
                            font-family: poppinsbold;
                            font-size: 12px;
                            text-transform: none;
                          "
                          >Continue</span
                        >
                      </v-btn>
                    </v-flex>
                    <v-flex xs12>
                      <v-layout wrap>
                        <v-flex xs12 text-center>
                          <span
                            style="
                              font-family: sofialight;
                              font-size: 12px;
                              color: #7b7b7b;
                            "
                            >Didn't receive code? &nbsp;</span
                          >

                          <span
                            v-if="!otpexpired"
                            style="
                              font-family: sofialight;
                              font-size: 12px;
                              color: #393c4a;
                            "
                            >Please Wait {{ timeLeft }}</span
                          >
                          <span
                            v-if="otpexpired"
                            style="
                              font-family: sofialight;
                              font-size: 12px;
                              color: #393c4a;
                              cursor: pointer;
                            "
                            @click="sentOTP"
                            >Resent</span
                          >
                        </v-flex>
                      </v-layout>
                      <v-layout v-if="msg2" py-4 wrap>
                        <v-flex text-center>
                          <span
                            style="
                              font-family: poppinslight;
                              font-size: 14px;
                              color: red;
                            "
                            >{{ msg2 }}</span
                          >
                        </v-flex>
                      </v-layout>
                    </v-flex>

                    <v-flex py-12>
                      <span style="color: #fff">.</span>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <!-- </v-app> -->
  </div>
</template>
<script>
import VieOtpInput from "@bachdgvn/vue-otp-input";
import axios from "axios";
export default {
  components: {
    VieOtpInput,
  },
  props: ["pageData", "lastPage", "backgroundImage"],
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      msg2: null,
      phone: null,
      otp: null,
      timeLeft: "00:00",
      intervalTimer: null,
      otpexpired: false,
    };
  },
  beforeMount() {
    this.setTime(180);
    this.phone = this.pageData.phone;
  },
  methods: {
    validateOTP(value) {
      this.otp = value;
      this.validateInput();
    },
    validateInput() {
      this.login();
      // this.$emit("stepper", {
      //   winStep: 0,
      // });
    },
    login() {
      this.appLoading = true;
      var userData = {};
      userData["phone"] = this.pageData.phone;
      userData["otp"] = this.otp;
      axios({
        method: "POST",
        url: "user/signup/signin",
        data: {
          accessType: "signin",
          phone: this.pageData.phone,
          otp: this.otp,
          role: "customer",
          guestId: localStorage.getItem('GuestID')
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            if (response.data.role == "customer") {
              // new wti

              this.$store.commit("userType", response.data.role);
              this.$store.commit("userData", response.data.data);
              this.$store.commit("changeCart", response.data.cartcount);

              this.$store.commit("loginUser", response.data.token);
              // new wti

              localStorage.setItem("token", response.data.token);

              if (response.data.profileStatus == "active") {
                this.$store.commit("userData", response.data.data);
                // this.$router.push("/Shopping");
              }
            } else {
              this.msg2 = "Please try after some time!";
            }
          } else {
            this.msg2 = response.data.msg;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    sentOTP() {
      var userData = {};
      userData["phone"] = this.pageData.phone;
      this.appLoading = true;

      // axios({
      //   method: "get",
      //   url: "user/signup/signin/send/otp",
      //   params: {
      //     phone: this.phone,
      //     accessType: this.userType,
      //   },
      // })
      //   .then((response) => {
      //     this.appLoading = false;
      //     if (response.data.status) {
      //       if (response.data.alreadyExists == false) {
      //         this.$emit("stepper", {
      //           phone: this.phone,
      //           winStep: 2,
      //         });
      //       }
      //       if (response.data.alreadyExists == true) {
      //         this.$emit("stepper", {
      //           phone: this.phone,
      //           winStep: 1,
      //         });
      //       }
      //     } else {
      //       // this.$emit("stepper", {
      //       //   phone: this.phone,
      //       //   winStep: 2,
      //       // });
      //       this.showSnackBar = true;
      //       this.msg = response.data.msg;
      //     }
      //   })
      //   .catch((err) => {
      //     this.ServerError = true;
      //     console.log(err);
      //   });
      axios({
        method: "GET",
        url: "user/signup/signin/send/otp",
        params: {
          phone: this.phone,
          accessType: this.userType,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.setTime(180);
            this.otpexpired = false;
            this.msg = "OTP Sent Successfully";
            this.showSnackBar = true;
          } else {
            this.msg = "Something Went Wrong";
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    setTime(seconds) {
      clearInterval(this.intervalTimer);
      this.timer(seconds);
    },
    timer(seconds) {
      const now = Date.now();
      const end = now + seconds * 1000;
      this.displayTimeLeft(seconds);
      this.countdown(end);
    },
    countdown(end) {
      this.intervalTimer = setInterval(() => {
        const secondsLeft = Math.round((end - Date.now()) / 1000);
        if (secondsLeft == 0) this.otpexpired = true;
        if (secondsLeft < 0) {
          clearInterval(this.intervalTimer);
          return;
        }
        this.displayTimeLeft(secondsLeft);
      }, 1000);
    },
    displayTimeLeft(secondsLeft) {
      const minutes = Math.floor((secondsLeft % 3600) / 60);
      const seconds = secondsLeft % 60;
      var seconds1 = seconds;
      if (seconds < 10) seconds1 = "0" + seconds;
      this.timeLeft = "0" + minutes + " : " + seconds1;
    },
  },
};
</script>
