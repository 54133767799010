<template>
  <div>
    <v-layout wrap justify-center>
      <v-flex xs12 class="align-self-start">
        <!-- <v-img :src="require('./../../assets/Images/bg.jpg')" class="align-center"> -->
          <v-layout wrap justify-center style="margin-top: 130px">
            <v-flex>
              <LoginBanner v-if="winStep == 3" @stepper="winStepper" />
              <AppLogin
                v-if="winStep == 0"
                v-bind:type="type"
                v-bind:pageData="pageData"
                @stepper="winStepper"
              />
              <AppSignup
                v-if="winStep == 4"
                v-bind:type="type"
                v-bind:pageData="pageData"
                @stepper="winStepper"
              />
              <!-- <AppLogin
                v-if="winStep == 0"
                v-bind:type="type"
                v-bind:pageData="pageData"
                v-bind:backgroundImage="backgroundImage"
                @stepper="winStepper"
              /> -->
              <LoginVerify
                v-if="winStep == 1"
                v-bind:pageData="pageData"
                v-bind:backgroundImage="backgroundImage"
                @stepper="winStepper"
                v-bind:lastPage="lastPage"
              />
              <SignUp
                v-if="winStep == 2"
                v-bind:pageData="pageData"
                v-bind:backgroundImage="backgroundImage"
                @stepper="winStepper"
                v-bind:lastPage="lastPage"
              />
            </v-flex>
          </v-layout>
        <!-- </v-img> -->
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
// import axios from "axios";
import LoginBanner from "./../Login/loginBanner";
import AppLogin from "./../Login/loginForm";
import AppSignup from "./../Login/signupForm";
import LoginVerify from "./../Login/loginVerify";
import SignUp from "./../Login/signUp";
export default {
  components: {
    AppLogin,
    AppSignup,
    LoginVerify,
    SignUp,
    LoginBanner,
  },
  data() {
    return {
      winStep: 0,
      type: 'signin',
      phone: null,
      pageData: {},
      lastPage: null,
      backgroundImage: null,
    };
  },
  beforeMount() {
    if (this.$route.params.lastPage)
      this.lastPage = this.$route.params.lastPage;
  },
  methods: {
    winStepper(window_data) {
      this.winStep = window_data.winStep;
      this.type = window_data.type;
      console.log(this.type);
      this.pageData.phone = window_data.phone;
      if (window_data.response)
        this.pageData.pageResponse = window_data.response;
    },
  },
};
</script>
<style>
.login_page {
  background-color: #2aa86638;
  background-attachment: fixed;
  background-size: cover;
}
</style>
