<template>
  <div>
    <v-layout wrap>
      <v-flex xs12>
        <!-- <v-img
          :src="require('./../../assets/Images/bg.jpg')"
          class="align-center"
          height="100vh"
        > -->
        <v-layout wrap justify-center pa-3 pa-sm-5 pa-lg-0>
          <v-flex xs6 pa-lg-16 align-self-start>
            <v-card tile elevation="22">
              <v-layout wrap>
                <v-flex xs12 text-left pb-8>
                  <v-img
                    cover
                    height="170px"
                    :src="require('./../../assets/Images/JobsHeader.jpg')"
                    class="align-center"
                  >
                    <v-layout wrap justify-center>
                      <v-flex xs12 align-self-center>
                        <v-img
                          contain
                          height="100px"
                          :src="
                            require('./../../assets/Images/footerLogo1.png')
                          "
                          class="align-center"
                        >
                        </v-img>
                      </v-flex>
                    </v-layout>
                  </v-img>
                </v-flex>

                <v-flex xs12 align-self-center>
                  <v-layout wrap>
                    <v-flex xs12 text-center py-3>
                      <span class="popbold">Welcome to WTI </span>
                      <br />
                      <br />

                      <!-- <v-btn
                        :color="appColor"
                        dark
                        rounded
                        style="
                          padding-left: 30px;
                          padding-right: 30px;
                          text-transform: none;
                        "
                        @click="goTo"
                        small
                        >Login to shop with us!</v-btn
                      > -->
                      <!-- <AppLogin
                        v-bind:type="'signin'"
                        @stepper="winStepper"
                      /> -->
                      <AppLogin
                        v-bind:type="type"
                        v-bind:pageData="pageData"
                        @stepper="winStepper"
                      />
                    </v-flex>
                    <v-flex xs12 text-center py-3>
                      <span class="popregular">Not a member? </span>
                      <v-btn
                        color="#242B48"
                        dark
                        rounded
                        style="
                          padding-left: 30px;
                          padding-right: 30px;
                          text-transform: none;
                        "
                        @click="goTosignup"
                        small
                        >Signup</v-btn
                      >
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
          <!-- <v-flex xs6 pt-6>
              <v-img height="250px" contain :src="require('./../../assets/Images/bannerJob.jpg')">
              </v-img>
            </v-flex> -->
        </v-layout>
        <!-- <v-layout pt-8 px-lg-16>
            <v-flex xs12 px-lg-16>
              <v-card>
                <v-layout wrap style="background-color: #121212">
                  <v-flex xs6>
                    <v-layout wrap pa-6>
                      <v-flex xs12 text-left>
                        <span style="color: white" class="sofiaPro">
                          Start selling today</span
                        >
                      </v-flex>
                      <v-flex xs12 text-left>
                        <span
                          style="color: white; line-height: 0.05"
                          class="xsmall ibmsemibold"
                        >
                          lorem ipsum is a simple dummt text of the printing
                          <br />
                          and setting industry
                        </span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs6 align-self-end>
                    <v-img
                      class="mx-auto"
                      height="100px"
                      contain
                      src="../../assets/Images/iphone.png"
                    >
                    </v-img>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
          </v-layout> -->
        <!-- </v-img> -->
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import AppLogin from "./loginForm";
export default {
  components: {
    AppLogin,
  },
  data() {
    return {};
  },
  methods: {
    winStepper(window_data) {
      this.winStep = window_data.winStep;
      this.type = window_data.type;
      console.log(this.type);
      this.pageData.phone = window_data.phone;
      if (window_data.response)
        this.pageData.pageResponse = window_data.response;
    },
    goTo() {
      this.$emit("stepper", {
        winStep: 0,
        type: "signin",
      });
    },
    goTosignup() {
      this.$emit("stepper", {
        winStep: 0,
        type: "signup",
      });
    },
  },
};
</script>
<style>
/* .sland {
  -webkit-transform: skewy(-3deg);
  transform: skewy(-3deg);
}
.sland2 {
  -webkit-transform: skewy(+3deg);
  transform: skewy(+3deg);
}
.sland3 {
  -webkit-transform: skewy(-2deg);
  transform: skewy(-2deg);
} */
</style>
